import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';

import Card from '@components/containers/Card';
import LoggedPage from '@components/containers/LoggedPage';
import FlexBox from '@components/containers/FlexBox';
import Input from '@components/elements/Input';
import Anchor from '@components/elements/Anchor';
import Paragraph from '@components/elements/Paragraph';
import Button from '@components/elements/Button';
import Headline from '@components/elements/Headline';

import useCustomerBankAccounts from '@utils/useCustomerBankAccounts';

import { updateBankAccountPayin } from '@redux/actions/accounts';

const Mandate = ({ accountId }) => {
  const dispatch = useDispatch();

  const currentBankAccountIdPayin = useSelector(({ accounts }) => accounts.loans[accountId]
    && accounts.loans[accountId].bankAccountIdPayin, shallowEqual);
  const {
    loading,
    signedBankAccounts,
  } = useCustomerBankAccounts(currentBankAccountIdPayin);

  const [selectedBankAccount, setSelectedBankAccount] = useState(currentBankAccountIdPayin);

  const selectClick = useCallback(() => {
    dispatch(updateBankAccountPayin(accountId, selectedBankAccount));
  }, [dispatch, accountId, selectedBankAccount]);

  const onChange = (_field, value) => setSelectedBankAccount(value);

  const isSelectable = selectedBankAccount !== currentBankAccountIdPayin;

  return (
    <LoggedPage
      title="Medgivande"
      previousUrl={getUrl(urls.HOME.MY_LOANS.DETAILS, { loanId: accountId })}
    >
      <Card loading={loading}>
        <FlexBox flexDirection="column" alignItems="center">
          {signedBankAccounts.length ? (
            <>
              <FlexBox
                flexDirection="column"
                alignItems="flexStart"
                margin="regular"
              >
                <Headline
                  fontWeight="bold"
                  fontSize="tiny"
                  as="h2"
                  marginBottom="none"
                  marginRight="small"
                >
                  Välj medgivande
                </Headline>
                <Input
                  _id="bankAccountIdPayin"
                  inputType="select"
                  label="Välj det autogiromedgivande som du vill använda till den valda produkten:"
                  value={selectedBankAccount}
                  onChange={onChange}
                  values={signedBankAccounts}
                  hasError={false}
                  defaultOption="Välj alternativ"
                />
                <Paragraph margin="none">
                  <Anchor underlined to={getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId })}>+ Lägg till nytt medgivande</Anchor>
                </Paragraph>
              </FlexBox>
              <Button onClick={selectClick} disabled={!isSelectable} size="small">Anslut bankkonto</Button>
            </>
          ) : (
            <>
              <FlexBox
                flexDirection="column"
                alignItems="flexStart"
                marginTop="regular"
                marginBottom="big"
              >
                <Paragraph>Du har inget autogiromedgivande för nuvarande.</Paragraph>
              </FlexBox>
              <Button to={getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId })} size="small">Lägg till medgivande</Button>
            </>
          )}
        </FlexBox>
      </Card>
    </LoggedPage>
  );
};

Mandate.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default Mandate;
